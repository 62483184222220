<template>
	<div>
		<DetailForm
			:is-success="isSuccess"
			class="customer-appt-detail"
			@new-appt="goToApptForm"
			@cancel-appt="cancelAppt"
		></DetailForm>
	</div>
</template>

<script>
import { SEARCH_APPT, CANCEL_APPOINTMENT } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("serviceAppointment")

export default {
	name: "CustomerApptDetail",

	components: {
		DetailForm: () => import("./../components/DetailForm.vue"),
	},

	props: {
		isSuccess: {
			type: Boolean,
			default: false,
		},
		contactTool: {
			type: String,
			default: null,
		},
		contactInfo: {
			type: String,
			default: null,
		},
		code: {
			type: String,
			default: null,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		const params = {
			code: this.code,
		}
		if (this.contactInfo) {
			params.contactTool = this.contactTool
			params.contactInfo = this.contactInfo
		}
		this.SEARCH_APPT(params)

		// this.postToIframe()
	},

	methods: {
		...mapActions({ SEARCH_APPT, CANCEL_APPOINTMENT }),

		goToApptForm() {
			this.$router.push({ name: "CustomerCreateAppt" })
		},

		cancelAppt(params) {
			this.CANCEL_APPOINTMENT({
				id: params.id,
				code: params.code,
			})
		},

		postToIframe() {
			window.parent.postMessage("service-appt", "*")
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.customer-appt-detail {
	background-color: $color-white;
	.appt-detail {
		@include form;
		@include font-lato;

		&__heading {
			font-size: 16px;
			font-weight: 400;
			color: $color-black;

			h1 {
				font-size: 32px;
				color: $color-bahama-blue;
			}

			a {
				color: $color-pink3;
				font-weight: bold;
				font-size: 16px;
			}

			p {
				color: $color-siam;
				max-width: 670px;
			}
		}

		&__heading + .h-divider {
			margin-top: 35px;
		}

		&__sub-heading {
			max-width: 670px;
			margin-top: 10px;

			p {
				margin-bottom: 6px;
			}
		}

		&__container {
			.h-divider {
				margin: 16px 0;
			}
		}

		&__action {
			display: flex;
			justify-content: flex-end;
			gap: 10px;

			.btn {
				font-weight: bold;
			}
		}

		.clinic {
			.tab-content {
				max-height: 340px;
				overflow: auto;
				margin: 10px 0;
			}
			p {
				margin-bottom: 4px;
			}
			&__name {
				color: $color-black;
				font-weight: bold;
			}
			&__address {
				display: block;
				font-size: 14px;
				margin-bottom: 12px;
				color: $color-black-pearl;
			}
		}

		.date-time img {
			position: relative;
			top: -2px;
			margin-left: 20px;
		}

		.service p {
			margin-bottom: 6px;
		}
	}

	.panel {
		&__label {
			color: $color-siam;
		}
		&__des {
			color: $color-black-pearl;
		}
		&__link {
			color: $color-primary;
			word-break: break-all;
		}
	}

	.form-group {
		display: flex;
		flex-wrap: wrap;
		gap: 2%;

		&__label {
			flex: 0 0 100%;
			max-width: 100%;
			font-size: 16px;
			font-weight: 700;
			color: $color-blue1;

			@media (min-width: $xxs) {
				flex: 0 0 24%;
				max-width: 24%;
			}
		}

		&__input {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: $xxs) {
				flex: 0 0 74%;
				max-width: 74%;
			}
		}

		.contact-icon {
			display: inline-block;
			padding: 2px 8px;
			background: rgba(38, 34, 48, 0.05);
			border-radius: 100px;
			margin-right: 10px;

			img {
				width: 20px;
				height: auto;
				position: relative;
				top: -2px;
				margin-right: 10px;
			}
		}
	}

	.form-group + .form-group::before {
		content: "";
		display: block;
		background: $color-whisper;
		width: 100%;
		height: 1px;
		margin-bottom: 24px;
	}

	.btn-pink {
		@include btn-pink;
	}
	.btn-ghost-pink {
		@include btn-ghost-pink;
	}

	.btn-finish,
	.btn-pink {
		padding: 14px 18px;

		@media (min-width: $xxs) {
			padding: 14px 32px;
		}
	}

	.btn-finish {
		font-weight: bold;
		background-color: $color-blue;
		border-color: $color-blue;
	}
}
</style>
